import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import configService from './services/config'

import './styles.scss'
(async () => { 
  await configService.init()

  const App = await (await import('./App')).App
  // const App = await (await import('./App'))

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
})()

